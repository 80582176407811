import React from 'react'
import Awards from './awards'
import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const AwardsPage = ({title, _rawBody, awards}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          {/* awards */}
          <Awards awards={awards} />

        </Container>
      </article>
    </>
  )
}
export default AwardsPage
