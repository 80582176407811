import React from 'react'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import styles from './awards.module.css'

const Awards = ({awards}) => {
  // console.log({awards})
  return (
    <>
      <div className={styles.awardsWrapper}>
        {awards
          .sort((a, b) => (a.awardYear > b.awardYear) ? -1 : 1)
          .map(award => (
            <div key={award._key} className={styles.awardWrapper}>
              <div className={styles.awardLogoWrapper}>
                <span className={styles.awardYear}>{award.awardYear}</span>
                {award.awardPresenterLogo && (<Img fixed={award.awardPresenterLogo.asset.fixed} alt={award.awardPresenterLogo.alt} />)}
              </div>

              <h3>{award.awardName}</h3>

              {award.connectedProject && award.connectedProject.hero && award.connectedProject.hero[0] && award.connectedProject.hero[0].asset && (<><Link to={`/portfolio/${award.connectedProject.slug.current}`}><Img fluid={award.connectedProject.hero[0].asset.fluid} alt={award.connectedProject.title} /></Link></>
              )}

              {award.connectedProject &&
                award.connectedProject.hero[0] &&
                award.connectedProject.hero[0].slides[0] &&

             (<>
               <Link to={`/portfolio/${award.connectedProject.slug.current}`}><Img fluid={award.connectedProject.hero[0].slides[0].slideImage.asset.fluid} alt={award.connectedProject.title} /></Link></>
             )}

              <p className={styles.info}>

                <span className={styles.label}>project</span>
                {award.connectedProject ? (
                  <><Link to={`/portfolio/${award.connectedProject.slug.current}`}>{award.connectedProject.title}</Link></>
                ) : (
                  <>{award.awardWinningProject}</>
                )}

                {award.awardCategory && (<><span className={styles.label}>category</span>{award.awardCategory}</>)}
              </p>

            </div>
          ))}
      </div>
    </>
  )
}

export default Awards
