import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page/awards/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityAwardsPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      awards {
        _key
        _type
        awardPresenter
        awardName
        awardYear
        awardWinningProject
        awardCategory
        connectedProject {
          title
          hero {
            ... on SanityMainImage {
              _key
              asset {
                fluid(maxWidth: 480, maxHeight: 368) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            ... on SanitySlideshow {
              _type
              slides {
                slideImage {
                  _key
                  asset {
                    fluid(maxWidth: 480, maxHeight: 368) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          slug {
            current
          }
        }
        awardPresenterLogo {
          asset {
            fixed(width: 200) {
              ...GatsbySanityImageFixed
            }
          }
          alt
        }
      }
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
            }
         }
      }
    }
  }
`

const AwardPage = ({data: {sanityAwardsPage: page}}) => (
  <Layout hero={page.hero} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      awards={page.awards}
    />
  </Layout>
)

export default AwardPage
